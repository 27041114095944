:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}

#clients {
    background-image: url('../../Assets/Images/clients.png');
    background-size: 100% 100% !important;
    height: auto;
    margin-top: 5%;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.card-list {
    justify-content: space-evenly;
    display: flex;
}

.btn-prev {
    border: none;
    padding: 5px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: white;
    color: var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.btn-prev:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.btn-next {
    border: none;
    padding: 5px 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: white;
    color: var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.btn-next:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


@media (min-width: 200px) and (max-width: 911px) {
    .card-list {
        justify-content: space-evenly;
        display: grid;
        margin-bottom: 15px;
    }

    .client-stat-cards {
        display: grid;
        /* Change to grid to take advantage of grid layout */
        justify-items: center;
        grid-template-columns: repeat(2, auto);
        /* Create two equal columns */
        gap: 15px;
        margin-top: 5%;
    }
}

@media (min-width: 912px) and (max-width: 2000px) {
    .card-list {
        justify-content: space-evenly;
        display: flex;
    }

    .client-stat-cards {
        display: flex;
        justify-content: space-evenly;
        margin-top: 5%;
    }
}