

/* main theme colors */
:root {
  --primary-color: #4691F2;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --text-color: #333333;
  --background-color: #f5f5f5;
  --dark-color: #6C6C6C;
}

.btn-primary{
  color: var(--primary-color) !important;
}

.background-transparent{
  background-color: transparent !important;
}




