/* main theme colors */
:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}

#contactus {
    background-image: url('../../Assets/Images/clients.png');
    background-size: 100% 100% !important;
    height: auto;
    margin-top: 5%;
}

.contactus-header {
    background-image: url('../../Assets/Images/bg_contactus.png');
    background-size: 100% 100% !important;
    height: auto;
    color: white;

    .contactus-header-content {
        padding: 8%;
        display: flex;
        flex-direction: column;
        /* Stack elements vertically */
        align-items: center;
        /* Center horizontally */
        text-align: center;

        h3 {
            font-size: 34px;
            font-weight: 600;
        }

        p {
            font-weight: 500;
            color: white !important;
        }

        a {
            background-color: white;
            border-radius: 10px;
            border: none !important;
            padding: 10px;
            color: var(--primary-color);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s ease;
            text-decoration: none;
        }

        a:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
    }
}

.contactus-form {
    margin-top: 5%;
    margin-bottom: 5%;

    .form-control {
        border: none;
        border-bottom: 2px lightgray solid;
        border-radius: 0px !important;
        background-color: transparent !important;
    }

                .form-control:focus {
                    border-bottom: 2px black solid;
                }

    .no-shadow:focus {
        box-shadow: none;
        /* Remove the box shadow */
        outline: none;
        /* Optional: Remove the outline */
    }

    .contactus-form-submit-btn {
        background-color: #4691F2;
        color: white;
        border: none !important;
        padding: 10px;
        border-radius: 20px;
        width: 20%;
        margin-top: 5%;
    }

}