.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* width: 80%; */
    margin: auto;
}

.slider-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    justify-content: center;
    /* width: 100%; */
}

.slider-card-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.slider-card-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.services-details-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Shadow effect on hover */
}


@media (min-width: 200px) and (max-width: 911px) {
    #services {
        background-image: url('../../Assets/Images/services.png');
        background-size: 100% 100% !important;
        height: auto;
        margin-top: 10%;
    }

    .services-details-container {
        width: auto;
        margin: 10px;
        background-color: white;
        padding: 3%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 10px;

        h3 {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .slider-card {
        flex: 0 0 20%;
        margin: 0 0px;
        text-align: center;
        padding: 100px 0;
        transition: transform 0.5s ease-in-out;
        height: 200px;
        width: 250px;
        z-index: 1;
        color: white;

        h3 {
            font-size: 10px;
            transition: transform 0.5s ease-in;
        }
    }

    .slider-card.active {
        transform: scale(1.2);
        /* Larger centered image */
        opacity: 1 !important;
        z-index: 2;

        h3 {
            font-size: 14px;
            transition: transform 0.5s ease-out;
        }
    }

}

@media (min-width: 912px) and (max-width: 2000px) {
    #services {
        background-image: url('../../Assets/Images/services.png');
        background-size: 100% 100% !important;
        height: auto;
        margin-top: 10%;
    }

    .services-details-container {
        width: 50%;
        margin-left: 25%;
        margin-top: 3%;
        background-color: white;
        padding: 3%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 10px;

        h3 {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .slider-card {
        flex: 0 0 20%;
        margin: 0 0px;
        text-align: center;
        padding: 100px 0;
        transition: transform 0.5s ease-in-out;
        height: 200px;
        width: 250px;
        z-index: 1;
        color: white;

        h3 {
            font-size: 20px;
            transition: transform 0.5s ease-in;
        }
    }

    .slider-card.active {
        transform: scale(1.2);
        /* Larger centered image */
        opacity: 1 !important;
        z-index: 2;

        h3 {
            font-size: 24px;
            transition: transform 0.5s ease-out;
        }
    }
}