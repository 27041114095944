.client-stat-outer-circle {
    width: 100px;
    height: 100px;
    background-color: rgba(70, 145, 242, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-stat-inner-circle {
    width: 90px;
    height: 90px;
    background-color: #4691F2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
}

.client-stat-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Shadow effect on hover */
}

.client-stat-header {
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
}

.client-stat-text {
    text-align: center;
    font-size: smaller;
}

@media (min-width: 200px) and (max-width: 911px) {
    .client-stat-card {
        padding: 10%;
        border: none !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 8px !important;
        width: 90%;
        justify-items: center;
        display: grid;
        background-color: white;
    }
}

@media (min-width: 912px) and (max-width: 2000px) {
    .client-stat-card {
        padding: 2%;
        border: none !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 8px !important;
        width: 20%;
        justify-items: center;
        display: grid;
        background-color: white;
    }
}