/* main theme colors */
:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}


.navbar-btn-default {
    position: relative;
    color: var(--dark-color);
}

.navbar-btn-default:hover {
    color: var(--primary-color) !important;
}

.navbar-btn-default.active {
    color: var(--primary-color) !important;    
}


.navbar-space{
    padding-inline: 4%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-btn-margin{
    margin-left: auto;
}

@media (min-width: 200px) and (max-width: 911px) {
    .navbar-btn-margin {
            margin-left: auto;
        }
    .navbar-logo {
        width: 40px;
        height: 40px;
        margin: 0px;
    }
}

@media (min-width: 912px) and (max-width: 2000px) {
    .navbar-btn-margin {
        /* margin-left: auto; */
    }
    .navbar-logo {
        width: 40px;
        height: 40px;
        margin: -20px;
    }
    .navbar-btn-default.active::after {
        content: '';
        position: absolute;
        bottom: 4px;
        /* Adjust this value to position the circle below the button */
        left: 50%;
        transform: translateX(-50%);
        width: 7px;
        /* Adjust for circle size */
        height: 7px;
        /* Adjust for circle size */
        background-color: var(--primary-color);
        border-radius: 50%;
    }
}