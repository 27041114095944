:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}

.footer-row{
    background-color: var(--primary-color);
    padding: 5px;
    text-align: center;
}

.icon-color{
    color: var(--primary-color);
    cursor: pointer;
}

.footer-container{
    background: linear-gradient(180deg, rgba(70, 145, 242, 0.1) 0%, rgba(70, 145, 242, 0) 100%);

    a{
        text-decoration: none;
        margin-top: 5px;
    }
}