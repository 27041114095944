:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}


/* AboutUs.css */
#aboutus {
    background-image: url('../../Assets/Images/aboutus.png');
    background-size: 100% 100% !important;
    height: auto;
}

.h3-aboutus-text{
    color: var(--primary-color);
    margin-top: 5px;
    margin-bottom: 15px;
}

.h1-aboutus-heading{
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.aboutus-container{
    margin-top: 10%;
    margin-left: 15%;
}

.aboutus-list{
    margin-top: 2%;
    margin-left: 10%;
}

.aboutus-list-icon{
    color: var(--primary-color);
}

@media (min-width: 200px) and (max-width: 911px) {
    .img-aboutus {
            display: none;
    }

        .aboutus-container {
            margin-top: 12%;
            margin-left: 0px;
        }
}

@media (min-width: 912px) and (max-width: 2000px) {
    .img-aboutus{
        display: block;
    }

        .aboutus-container {
            margin-top: 10%;
            margin-left: 15%;
        }
}