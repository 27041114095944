:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}


.card-text {
    font-size: small;
    color: var(--dark-color);
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Shadow effect on hover */
}

.card-company-info{
    font-size: x-small;
    font-weight: 600;
}

.card-rating {
    display: flex;
    align-items: baseline;
}

.card-rating-header{
    font-size: larger !important;
    font-weight: 500;
    margin-right: 2px;
}

.card-icon{
    color: var(--primary-color);
}

.card-child-row{
    display: flex;
    justify-content: space-between;
}

@media (min-width: 200px) and (max-width: 911px) {
.card {
        padding: 5px;
        border: none !important;
        width: 18rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 8px !important;
        margin-bottom: 5%;
    }
}

@media (min-width: 912px) and (max-width: 2000px) {
.card {
        padding: 5px;
        border: none !important;
        width: 18rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        border-radius: 8px !important;
    }
}