:root {
    --primary-color: #4691F2;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --dark-color: #6C6C6C;
    --dark-black-color: #1A1313;
}


#jq-home-section-id{
    height: 100vh;
}


.home-h6{
    margin-top: 20%;
}

.home-h1 {
    margin-top: 8%;
    margin-bottom: 8%;
    font-weight: 900;
}

.home-text{
    font-size: smaller;
    color: var(--text-color);
}

.home-appointment-btn{
    background-color: #4691F2;
    color: white;
    border: none !important;
    padding: 10px;
    border-radius: 20px;
    width: 60%;
    padding-left: 10px;
    text-decoration: none;
}

.text-align-left{
    text-align: left;
}



@media (min-width: 200px) and (max-width: 911px) {
    .img-worker {
            width: 100%;
        }
}

@media (min-width: 912px) and (max-width: 2000px) {
    .img-worker {
        width: fit-content;
    }
}